@import url('https://fonts.googleapis.com/css2?family=Bangers&family=Honk&family=Madimi+One&display=swap');

.App-desktop {
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: "Honk", system-ui;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "MORF" 15,
    "SHLN" 50;
}

.App-mobile {
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: "Bangers", system-ui;
  font-weight: 400;
  font-style: normal;
}

.App-header {
  background-color: #ffffff;
  color: #191919;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  overflow: hidden;
}

.App-title-desktop-landscape {
  position: fixed;
  z-index: 500;
  top: -0.8em;
  left: 0.2em;
}

.App-title-desktop-portrait {
  position: fixed;
  z-index: 500;
  top: -0.8em;
  left: 0.2em;
}

.App-title-mobile-landscape {
  position: fixed;
  z-index: 500;
  top: -0.6em;
  left: 0.2em;
  color: rgb(12, 12, 12);
  background-color: #ff5ab9;
  background-image: linear-gradient(#ff5ab9, #ffffbc);
  padding-left: 0.2em;
  padding-right: 0.2em;
  letter-spacing: 0.1rem;
}

.App-title-mobile-portrait {
  position: fixed;
  z-index: 500;
  top: -0.6em;
  text-align: center;
  font-size: 1.9rem;
  color: rgb(12, 12, 12);
  background-color: #ff5ab9;
  background-image: linear-gradient(#ff5ab9, #ffffbc);
  padding-left: 0.2em;
  padding-right: 0.2em;
  letter-spacing: 0.1rem;
}

.App-slogan-desktop-landscape {
  position: fixed;
  z-index: 500;
  top: 2em;
  left: 0.2em;
  text-align: left;
  max-width: 12em;
}

.App-slogan-desktop-portrait {
  position: fixed;
  z-index: 500;
  top: 2em;
  left: 0.2em;
  text-align: left;
  max-width: 12em;
}

.App-slogan-mobile-landscape {
  position: fixed;
  z-index: 500;
  top: 2em;
  left: 0.2em;
  text-align: left;
  max-width: 12em;
  color: rgb(12, 12, 12);
  background-color: #ff5ab9;
  background-image: linear-gradient(#ff5ab9, #ffffbc);
  padding-left: 0.2em;
  padding-right: 0.2em;
  letter-spacing: 0.1rem;
}

.App-slogan-mobile-portrait {
  position: fixed;
  z-index: 500;
  top: 1.2em;
  text-align: center;
  font-size: 1.2rem;
  margin-left: 1.6em;
  margin-right: 1.6em;
  color: rgb(12, 12, 12);
  background-color: #ff5ab9;
  background-image: linear-gradient(#ff5ab9, #ffffbc);
  padding-left: 0.2em;
  padding-right: 0.2em;
  letter-spacing: 0.06rem;
}

.App-twitter-desktop-landscape {
  position: fixed;
  z-index: 500;
  top: 11em;
  left: 0.6em;
  height: 3.125rem;
  width: 3.125rem;
  cursor: pointer;
}

.App-twitter-desktop-portrait {
  position: fixed;
  z-index: 500;
  top: 11em;
  left: 0.6em;
  height: 4rem;
  width: 4rem;
  cursor: pointer;
}

.App-twitter-mobile-landscape {
  position: fixed;
  z-index: 500;
  top: 11em;
  left: 0.6em;
  height: 2.6rem;
  width: 2.6rem;
  cursor: pointer;
}

.App-twitter-mobile-portrait {
  position: fixed;
  z-index: 500;
  height: 2.6rem;
  width: 2.6rem;
  bottom: 19.5em;
  left: 0.6em;
  cursor: pointer;
}

.App-twitter:hover {
  scale: 1.1;
}

.App-twitter-desktop-landscape:hover {
  scale: 1.1;
}

.App-twitter-desktop-portrait:hover {
  scale: 1.1;
}

.App-twitter-mobile-landscape:hover {
  scale: 1.1;
}

.App-twitter-mobile-portrait:hover {
  scale: 1.1;
}

.App-dexscreener-desktop-landscape {
  position: fixed;
  z-index: 500;
  top: 11em;
  left: 3em;
  height: 3.125rem;
  width: 3.125rem;
  cursor: pointer;
}

.App-dexscreener-desktop-portrait {
  position: fixed;
  z-index: 500;
  top: 11em;
  left: 3em;
  height: 4rem;
  width: 4rem;
  cursor: pointer;
}

.App-dexscreener-mobile-landscape {
  position: fixed;
  z-index: 500;
  top: 11em;
  left: 3.8em;
  height: 2.6rem;
  width: 2.6rem;
  cursor: pointer;
}

.App-dexscreener-mobile-portrait {
  position: fixed;
  z-index: 500;
  height: 2.6rem;
  width: 2.6rem;
  bottom: 16em;
  left: 0.6em;
  cursor: pointer;
}

.App-dexscreener:hover {
  scale: 1.1;
}

.App-dexscreener-desktop-landscape:hover {
  scale: 1.1;
}

.App-dexscreener-desktop-portrait:hover {
  scale: 1.1;
}

.App-dexscreener-mobile-landscape:hover {
  scale: 1.1;
}

.App-dexscreener-mobile-portrait:hover {
  scale: 1.1;
}

/* .App-coingecko-desktop-landscape {
  position: fixed;
  z-index: 500;
  top: 11em;
  left: 5.4em;
  height: 3.125rem;
  width: 3.125rem;
  cursor: pointer;
}

.App-coingecko-desktop-portrait {
  position: fixed;
  z-index: 500;
  top: 11em;
  left: 5.4em;
  height: 4rem;
  width: 4rem;
  cursor: pointer;
}

.App-coingecko-mobile-landscape {
  position: fixed;
  z-index: 500;
  top: 11em;
  left: 7em;
  height: 2.6rem;
  width: 2.6rem;
  cursor: pointer;
}

.App-coingecko-mobile-portrait {
  position: fixed;
  z-index: 500;
  height: 2.6rem;
  width: 2.6rem;
  bottom: 12.5em;
  left: 0.6em;
  cursor: pointer;
}

.App-coingecko:hover {
  scale: 1.1;
}

.App-coingecko-desktop-landscape:hover {
  scale: 1.1;
}

.App-coingecko-desktop-portrait:hover {
  scale: 1.1;
}

.App-coingecko-mobile-landscape:hover {
  scale: 1.1;
}

.App-coingecko-mobile-portrait:hover {
  scale: 1.1;
} */

.App-jupiter-desktop-landscape {
  position: fixed;
  z-index: 500;
  top: 11em;
  left: 5.4em;
  height: 3.125rem;
  width: 3.125rem;
  cursor: pointer;
}

.App-jupiter-desktop-portrait {
  position: fixed;
  z-index: 500;
  top: 11em;
  left: 5.4em;
  height: 4rem;
  width: 4rem;
  cursor: pointer;
}

.App-jupiter-mobile-landscape {
  position: fixed;
  z-index: 500;
  top: 11em;
  left: 7em;
  height: 2.6rem;
  width: 2.6rem;
  cursor: pointer;
}

.App-jupiter-mobile-portrait {
  position: fixed;
  z-index: 500;
  height: 2.6rem;
  width: 2.6rem;
  bottom: 12.5em;
  left: 0.6em;
  cursor: pointer;
}

.App-jupiter:hover {
  scale: 1.1;
}

.App-jupiter-desktop-landscape:hover {
  scale: 1.1;
}

.App-jupiter-desktop-portrait:hover {
  scale: 1.1;
}

.App-jupiter-mobile-landscape:hover {
  scale: 1.1;
}

.App-jupiter-mobile-portrait:hover {
  scale: 1.1;
}

/* .App-jupiter-desktop-landscape {
  position: fixed;
  z-index: 500;
  top: 11em;
  left: 7.8em;
  height: 3.125rem;
  width: 3.125rem;
  cursor: pointer;
}

.App-jupiter-desktop-portrait {
  position: fixed;
  z-index: 500;
  top: 11em;
  left: 7.8em;
  height: 4rem;
  width: 4rem;
  cursor: pointer;
}

.App-jupiter-mobile-landscape {
  position: fixed;
  z-index: 500;
  top: 11em;
  left: 10.2em;
  height: 2.6rem;
  width: 2.6rem;
  cursor: pointer;
}

.App-jupiter-mobile-portrait {
  position: fixed;
  z-index: 500;
  height: 2.6rem;
  width: 2.6rem;
  bottom: 9em;
  left: 0.6em;
  cursor: pointer;
}

.App-jupiter:hover {
  scale: 1.1;
}

.App-jupiter-desktop-landscape:hover {
  scale: 1.1;
}

.App-jupiter-desktop-portrait:hover {
  scale: 1.1;
}

.App-jupiter-mobile-landscape:hover {
  scale: 1.1;
}

.App-jupiter-mobile-portrait:hover {
  scale: 1.1;
} */

.App-disclaimer-desktop-landscape {
  position: fixed;
  z-index: 500;
  bottom: -0.8em;
  left: 0.2em;
}

.App-disclaimer-desktop-portrait {
  position: fixed;
  z-index: 500;
  bottom: -0.8em;
  left: 0.2em;
  max-width: 80%;
  text-align: left;
}

.App-disclaimer-mobile-landscape {
  position: fixed;
  z-index: 500;
  bottom: -0.8em;
  left: 0.2em;
  color: rgb(12, 12, 12);
  background-color: #ff5ab9;
  background-image: linear-gradient(#ff5ab9, #ffffbc);
  padding-left: 0.2em;
  padding-right: 0.2em;
}

.App-disclaimer-mobile-portrait {
  position: fixed;
  z-index: 500;
  bottom: -0.8em;
  left: 0.6em;
  font-size: 1rem;
  text-align: left;
  max-width: 17em;
  color: rgb(12, 12, 12);
  background-color: #ff5ab9;
  background-image: linear-gradient(#ff5ab9, #ffffbc);
  padding-left: 0.2em;
  padding-right: 0.2em;
}

.App-ca {
  position: fixed;
  z-index: 500;
  bottom: 1.2em;
  left: 0.6em;
  cursor: pointer;
}

.App-ca-desktop-landscape {
  position: fixed;
  z-index: 500;
  top: 1em;
  right: 2.2em;
  cursor: pointer;
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  letter-spacing: 2px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  background-color: #ff5ab9;
  background-image: linear-gradient(#ff5ab9, #ffffbc);
}

.App-ca-desktop-portrait {
  position: fixed;
  z-index: 500;
  top: 1em;
  right: 2.2em;
  cursor: pointer;
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.4rem;
  letter-spacing: 2px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  background-color: #ff5ab9;
  background-image: linear-gradient(#ff5ab9, #ffffbc);
}

.App-ca-mobile-landscape {
  position: fixed;
  z-index: 500;
  bottom: 3em;
  cursor: pointer;
  cursor: pointer;
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.8rem;
  letter-spacing: 2px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  background-color: #ff5ab9;
  background-image: linear-gradient(#ff5ab9, #ffffbc);
  width:60%;
  word-wrap:break-word;
}

.App-ca-mobile-portrait {
  position: fixed;
  z-index: 500;
  bottom: 6.6em;
  font-size: 1.4rem;
  text-align: center;
  cursor: pointer;
  font-family: "Madimi One", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 0.8rem;
  letter-spacing: 2px;
  text-decoration: none;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  background-color: #ff5ab9;
  background-image: linear-gradient(#ff5ab9, #ffffbc);
  width:60%;
  word-wrap:break-word;
}

.App-ca:hover {
  scale: 1.1;
}

.App-ca-desktop-landscape:hover {
  scale: 1.1;
}

.App-ca-desktop-portrait:hover {
  scale: 1.1;
}

.App-ca-mobile-landscape:hover {
  scale: 1.1;
}

.App-ca-mobile-portrait:hover {
  scale: 1.1;
}